body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  This ensures lastpass doesn't show up at all. There's an attribute for this as well, but since we
  don't have any password fields I'm adding this globally. When we add a login we can simply include
  an inverse rule that's specific for those components when the time comes.
  Onepassword was more annoying and couldn't be included in this rule. I added the attribute to 
  components to cover them.

  Think this largely covers the password managers we'll have to deal with, but we can deal with them
  as they come.
*/
[data-lastpass-icon-root] {
  display: none !important;
}
